<template>
  <div class="service-form-part">
    <ServiceTypeField
      :choices="getServiceTypes()"
      @result="serviceType = $event.value[0]"
      v-on="$listeners"
    />

    <h2 class="form-modal__part-title">
      {{ serviceSelectionTitle || $t('serviceSelectionTitle') }}
    </h2>

    <CustomDimensionSelectableField
      :choices="serviceSelections"
      :is-optional="true"
      :multiple="true"
      field="custom-dimension-2"
      @result="serviceSelection = $event.value[0]"
      v-on="$listeners"
    />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import CustomDimensionSelectableField from 'chimera/all/components/form/fields/customDimension/CustomDimensionSelectableField'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import ServiceTypeField from 'chimera/all/components/form/fields/serviceType/ServiceTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export const consumerKey = 'consumer'
export const corporateKey = 'corporate'

export default {
  name: 'ServiceComboFormPartPD6602',

  components: {
    ServiceField,
    ServiceTypeField,
    CustomDimensionSelectableField,
  },

  props: {
    serviceTypes: {
      type: Array,
      default: undefined,
    },

    serviceSelections: {
      type: Array,
      required: true,
    },

    serviceSelectionTitle: {
      type: String,
      default: '',
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceType: undefined,
      serviceSelection: undefined,
    }
  },

  watch: {
    /**
     * @param {Selectable} selection
     */
    serviceSelection(selection) {
      this.setServiceForTypeAndSelection(this.serviceType, selection)
    },

    /**
     * @param {Selectable} serviceType
     */
    serviceType(serviceType) {
      this.setServiceForTypeAndSelection(serviceType, this.serviceSelection)
    },
  },

  methods: {
    /**
     * @returns {*[]|Selectable[]}
     */
    getServiceTypes() {
      return (
        this.serviceTypes || [
          new Selectable(
            this.$t('consumerService'),
            this.$t('consumerService'),
            consumerKey,
          ),
          new Selectable(
            this.$t('corporateService'),
            this.$t('corporateService'),
            corporateKey,
          ),
        ]
      )
    },

    /**
     * @param {Selectable}  type
     * @param {Selectable}  selection
     */
    setServiceForTypeAndSelection(type, selection) {
      if (!type || !selection) {
        return
      }

      const serviceType = this.getServiceTypes().find(
        (serviceType) => serviceType.id === type.id,
      )
      const serviceSelection = this.serviceSelections.find(
        (serviceSelection) => serviceSelection.id === selection.id,
      )
      const service = serviceSelection.options[serviceType.value]

      /**
       * PD-3935 - Added original service to serviceSelections
       */
      if ('original' in serviceSelection.options) {
        const originalService =
          serviceSelection.options.original[serviceType.value]
        this.$store.dispatch('lead/add', {
          key: 'custom-dimension-2',
          value: originalService.id,
        })
      } else {
        this.$store.dispatch('lead/rm', 'custom-dimension-2')
      }

      this.$refs.service.setValue(service.id)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "serviceSelectionTitle": "Type opdracht",
    "consumerService": "Particulier",
    "corporateService": "Zakelijk"
  },
  "nl-BE": {
    "serviceSelectionTitle": "Type opdracht",
    "consumerService": "Particulier",
    "corporateService": "Zakelijk"
  },
  "es-ES": {
    "serviceSelectionTitle": "Tipo de solicitud",
    "consumerService": "Particular",
    "corporateService": "Empresas"
  },
  "de-DE": {
    "serviceSelectionTitle": "Auftragstyp",
    "consumerService": "Privatkunde",
    "corporateService": "Geschäftskunde"
  },
  "fr-BE": {
    "serviceSelectionTitle": "Type de projet",
    "consumerService": "Particulier",
    "corporateService": "Professionnels"
  },
  "fr-FR": {
    "serviceSelectionTitle": "Type de projet",
    "consumerService": "Particulier",
    "corporateService": "Professionnels"
  },
  "it-IT": {
    "serviceSelectionTitle": "Tipo di progetto",
    "consumerService": "Privato",
    "corporateService": "Aziendale"
  }
}
</i18n>
