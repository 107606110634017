<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <template v-if="experiment && experiment.isTreatment">
        <SortedServiceRadioFormPartPD6602
          :service-sort="orderServices"
          @change="onChange"
          @result="onResult"
        />
      </template>
      <template v-else-if="experiment">
        <SortedServiceRadioFormPart
          :service-sort="orderServices"
          @change="onChange"
          @result="onResult"
        />
      </template>
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import SortedServiceRadioFormPart from 'chimera/solarPanels/components/form/parts/service/SortedServiceRadioFormPart.nl'
import {
  solarPanelsPurchaseConsumerNL,
  solarPanelsRentConsumerNL,
} from 'chimera/solarPanels/service'
import SortedServiceRadioFormPartPD6602 from '~/components/form/parts/service/SortedServiceRadioFormPartPD6602.nl'
import NextStep from '~/pages/offertes-aanvragen/soort-dak-schatting-jaarlijks-electriciteitsverbruik'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    SortedServiceRadioFormPart,
    SortedServiceRadioFormPartPD6602,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      experiment: null,
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  /**
   * https://bambelo.atlassian.net/browse/PD-6602
   */
  async beforeMount() {
    // https://bambelo.absmartly.com/experiments/339
    this.experiment = await this.$store.dispatch('experiments/get', {
      id: 'PD-6602',
      source: ExperimentSource.ABSMARTLY,
    })
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },

    /**
     * Depending on the landing page the services should be ordered differently.
     *
     * @param services
     * @returns {*}
     */
    orderServices(services) {
      const landingPageIdentifier =
        this.$store.getters['configuration/getLandingPage']('identifier')
      const serviceIdToOrderOn =
        this.getLandingPageMapping()[landingPageIdentifier]

      // When not coming from a relevant landing page we keep ordering intact.
      if (!serviceIdToOrderOn) {
        return services
      }

      // When coming from the rent landing page the rent service should be ordered on top.
      return services.sort((service) => {
        const consumerId = service.options.consumer.id
        const corporateId = service.options.corporate.id
        if ([corporateId, consumerId].includes(serviceIdToOrderOn)) {
          return -1
        } else {
          return 1
        }
      })
    },
    /**
     * @returns {{}}
     */
    getLandingPageMapping() {
      const mapping = {}
      mapping['landing-page-zonnepanelenvergelijker-nl-huren'] =
        solarPanelsRentConsumerNL.id
      mapping['landing-page-zonnepanelenvergelijker-nl-kopen'] =
        solarPanelsPurchaseConsumerNL.id
      return mapping
    },
  },
}
</script>
