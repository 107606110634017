<template>
  <div>
    <ServiceComboFormPart
      :service-types="serviceTypes"
      :service-selections="serviceSelections"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import {
  solarPanelsPurchaseConsumerNL,
  solarPanelsPurchaseCorporateNL,
  solarPanelsPurchaseSolarCollectorsConsumerNL,
  solarPanelsPurchaseSolarCollectorsCorporateNL,
  solarPanelsRentConsumerNL,
  solarPanelsRentCorporateNL,
} from 'chimera/solarPanels/service'
import ServiceComboFormPart, {
  consumerKey,
  corporateKey,
} from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'SortedServiceRadioFormPart',

  components: {
    ServiceComboFormPart,
  },

  props: {
    /**
     * @type {string}
     */
    serviceSort: {
      type: Function,
      required: true,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable('Woonhuis', 'Woonhuis', consumerKey),
        new Selectable('Bedrijfspand', 'Bedrijfspand', corporateKey),
        new Selectable(
          'Agrarische onderneming',
          'Agrarische onderneming',
          corporateKey,
        ),
        new Selectable(
          'Groot zakelijke projecten',
          'Groot zakelijke projecten',
          corporateKey,
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Zonnepanelen koop',
          'Zonnepanelen koop',
          'Zonnepanelen koop',
          {
            consumer: solarPanelsPurchaseConsumerNL,
            corporate: solarPanelsPurchaseCorporateNL,
          },
        ),
        new Selectable(
          'Zonnepanelen huur',
          'Zonnepanelen huur',
          'Zonnepanelen huur',
          {
            consumer: solarPanelsRentConsumerNL,
            corporate: solarPanelsRentCorporateNL,
          },
        ),
        new Selectable(
          'Zonneboiler / Zonnecollectoren',
          'Zonneboiler / Zonnecollectoren',
          'Zonneboiler / Zonnecollectoren',
          {
            consumer: solarPanelsPurchaseSolarCollectorsConsumerNL,
            corporate: solarPanelsPurchaseSolarCollectorsCorporateNL,
          },
        ),
      ],
    }
  },

  /**
   * Let's order the services using the external function.
   */
  mounted() {
    this.serviceSort(this.serviceSelections)
  },
}
</script>
