<template>
  <div>
    <ServiceComboFormPartPD6602
      :service-selections="serviceSelections"
      :service-types="serviceTypes"
      service-selection-title="Combineer meer optionele producten"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import { solarPanelsPurchaseConsumerNL } from 'chimera/solarPanels/service'
import ServiceComboFormPartPD6602, {
  consumerKey,
  corporateKey,
} from '~/components/form/parts/service/ServiceComboFormPartPD6602'

export default {
  name: 'SortedServiceRadioFormPartPD6602',

  components: {
    ServiceComboFormPartPD6602,
  },

  props: {
    /**
     * @type {string}
     */
    serviceSort: {
      type: Function,
      required: true,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable('Woonhuis', 'Woonhuis', consumerKey),
        new Selectable('Bedrijfspand', 'Bedrijfspand', corporateKey),
        new Selectable(
          'Agrarische onderneming',
          'Agrarische onderneming',
          corporateKey,
        ),
        new Selectable(
          'Groot zakelijke projecten',
          'Groot zakelijke projecten',
          corporateKey,
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Thuisbatterijen',
          'Thuisbatterijen',
          'Thuisbatterijen',
          {
            consumer: solarPanelsPurchaseConsumerNL,
            corporate: solarPanelsPurchaseConsumerNL,
          },
        ),
        new Selectable('Airco', 'Airco', 'Airco', {
          consumer: solarPanelsPurchaseConsumerNL,
          corporate: solarPanelsPurchaseConsumerNL,
        }),
        new Selectable('Warmtepompen', 'Warmtepompen', 'Warmtepompen', {
          consumer: solarPanelsPurchaseConsumerNL,
          corporate: solarPanelsPurchaseConsumerNL,
        }),
      ],
    }
  },

  /**
   * Let's order the services using the external function.
   */
  mounted() {
    this.serviceSort(this.serviceSelections)
  },
}
</script>
